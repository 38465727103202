.section-isometricGrids{
	.button-menu {
		width: 50px;
		height: 30px;
		position: fixed;
		top: 3em;
		right: 3em;
		border: none;
		background: transparent;
		fill: #fff;
		cursor: pointer;
		pointer-events: auto;
	}

	.button-menu:focus {
		outline: none;
	}

	/* Header */
	.codrops-header {
		padding: 3vmax 0 0 3vmax;
		position: absolute;
		width: 100%;
		z-index: 2000;
		pointer-events: none;
	}

	.demo-1 .codrops-header {
		position: fixed;
	}

	.codrops-header h1 {
		margin: 0;
		letter-spacing: -0.035em;
		font-size: 4.65em;
		line-height: 1;
		font-weight: 900;
		color: #323F5C;
	}

	.codrops-header p {
		padding: 0;
		margin: 0;
	    font-size: 1.25em;
	    font-weight: bold;
	    color: #fff;
	}

	/* Top Navigation Style */
	.codrops-links {
		position: relative;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		pointer-events: auto;
		margin: 0 0 0 -0.5em;
	}

	.codrops-links::after {
		content: '';
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    width: 1px;
	    height: 110%;
	    background: #323F5C;
	    opacity: 0.1;
	    -webkit-transform: translateY(-50%) rotate3d(0, 0, 1, 22.5deg);
	    transform: translateY(-50%) rotate3d(0, 0, 1, 22.5deg);
	}

	.codrops-icon {
		display: inline-block;
		margin: 0.5em;
		width: 1.5em;
		text-decoration: none;
	}

	.codrops-icon span {
		display: none;
	}

	.codrops-icon::before {
		margin: 0 5px;
		text-transform: none;
		font-weight: normal;
		font-style: normal;
		font-variant: normal;
		font-family: 'codropsicons';
		line-height: 1;

		speak: none;
		-webkit-font-smoothing: antialiased;
	}

	.codrops-icon--drop::before {
		content: "\e001";
		color: #0099cc;
	}

	.codrops-icon--prev::before {
		content: "\e004";
	}

	/* Demo links */
	.codrops-demos {
		margin: 1.5em 0;
	}

	.codrops-demos a {
		display: inline-block;
		margin: 0 1em 0 0;
		font-weight: bold;
		pointer-events: auto;
	}

	.codrops-demos a.current-demo {
		color: #ce4555;
	}

	/* Content */
	.section--intro {
		position: relative;
		height: 75vh;
		max-height: 1200px;
	}

	.section--right {
		text-align: right;
	}

	// .section--intro {
	// 	background: linear-gradient(90deg, #58A8FB, #465683);
	// }
	//
	// .section--portraits {
	// 	background: linear-gradient(20deg, #F5F0F9, #A8CFF1);
	// }
	//
	// .section--shots {
	// 	background: linear-gradient(20deg, #D1D7EF, #5C61F5);
	// }
	//
	// .section--cards {
	// 	background: #FFB8CC;
	// }

	.section__heading {
		pointer-events: none;
		padding: 0;
		font-size: 4.5em;
		line-height: 1;
		margin: 0;
		color: #fff;
		position: relative;
		z-index: 1000;
		font-family: 'Playfair Display', serif;
	}

	.section__heading em {
		font-family: sans-serif;
		font-style: normal;
		text-transform: uppercase;
		font-size: 0.3em;
		border-top: 3px solid #fff;
		border-bottom: 3px solid #fff;
		padding: 0.15em 0;
		vertical-align: middle;
		display: inline-block;
		letter-spacing: 0.05em;
	}

	.section__subtitle {
		pointer-events: none;
		font-size: 1.25em;
		max-width: 500px;
		position: relative;
		z-index: 1000;
	}

	.section--portraits .section__subtitle {
		color: #688CB1;
	}

	.section--right .section__subtitle {
		margin: 1em 0 0 auto;
	}

	.interval {
		position: relative;
		padding: 30vh 20vw;
		background: #1C222F;
		overflow: hidden;
	}

	.interval__text {
		font-size: 1.75em;
		line-height: 1.5;
		color: #777792;
	}

	/* Related demos */
	.content--related {
		text-align: center;
		font-weight: bold;
		padding: 10vh 2vw;
		background: #1C222F;
		color: #fff;
	}

	.media-item {
		display: inline-block;
		padding: 1em;
		vertical-align: top;
		-webkit-transition: color 0.3s;
		transition: color 0.3s;
	}

	.media-item__img {
		max-width: 100%;
		opacity: 0.3;
		-webkit-transition: opacity 0.3s;
		transition: opacity 0.3s;
	}

	.media-item:hover .media-item__img,
	.media-item:focus .media-item__img {
		opacity: 1;
	}

	.media-item__title {
		margin: 0;
		padding: 0.5em;
		font-size: 1em;
	}

	.pater {
		position: fixed;
		right: 0;
		bottom: 0;
		color: #fff;
		width: 320px;
		padding: 2em 2em 2em 0;
		text-align: right;
		z-index: 100000;
	}

	.demo-2 .pater {
		position: absolute;
	}

	.pater::before {
		content: '';
		position: absolute;
		background: url(../img/ink.png) no-repeat top left;
		width: 491px;
		height: 332px;
		top: -80px;
		left: -60px;
		z-index: 0;
		opacity: 0.9;
		-webkit-transform: translate3d(10px,10px,0);
		transform: translate3d(10px,10px,0);
		-webkit-transition: opacity 0.3s, -webit-transform 0.3s;
		transition: opacity 0.3s, transform 0.3s;
		pointer-events: none;
	}

	.pater:hover::before {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}

	.pater__title::before {
		content: 'Sponsored by';
		position: absolute;
		bottom: 93%;
		left: 27%;
		font-weight: bold;
		letter-spacing: 2px;
		font-size: 0.45em;
		color: #212738;
	}

	.pater__title,
	.pater__desc {
		margin: 0;
		color: #fff;
		position: relative;
	}

	.pater__title {
		-webkit-transform: translate3d(5px,10px,0);
		transform: translate3d(5px,10px,0);
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
	}

	.pater:hover .pater__title {
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}

	.pater__title img {
		width: 90%;
	}

	.pater__desc {
		font-weight: 600;
		padding: 0.3em 0 0 0.3em;
	}

	@media screen and (max-width: 50em) {
		.codrops-header {
			width: 80%;
		}
		.codrops-header h1 {
			font-size: 2em;
		}
		.codrops-header p {
			font-size: 0.85em;
		}
		.interval {
			padding: 1.5em;
		}
		.interval__text {
			font-size: 1.25em;
			padding: 0 0 3em 0;
		}
		.section__heading {
			font-size: 2.5em;
		}
		.section__subtitle {
			display: none;
		}
		.codrops-demos {
			margin: 0.5em 0;
			font-size: 0.75em;
		}
		.button-menu {
			top: 0.75em;
			right: 0.75em;
			width: 30px;
			height: 20px;
		}
		.demo-1 .pater {
			width: 100%;
			padding: 1em;
			text-align: left;
			background: rgba(49,63,105,0.8);
			-webkit-transform: translateZ(200px);
			transform: translateZ(200px);
		}
		.demo-1 .pater__title::before {
			left: 0;
		}
		.demo-1 .pater__title img {
			width: 150px;
			margin-top: 0.25em;
		}
		.demo-1 .pater::before {
			display: none;
		}
		.demo-1 .pater__desc {
			font-size: 0.65em;
		}
		.isolayer.isolayer--scroll1 {
			top: -38vh;
		}
		.demo-2 .pater {
			position: relative;
			display: block;
			background: none;
			margin: 0 0 0 auto;
			padding: 0;
			width: 100%;
		}
	}


	.isolayer {
		position: absolute;
		top: 0;
		left: 0;
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}

	.section--intro .grid,
	.section--intro .grid__item,
	.section--intro .grid__link {
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}

	.grid {
		position: relative;
		margin: 0 auto;
		padding: 0;
		list-style: none;
	}

	.grid__item {
		width: 300px;
	}

	.section--intro .grid__item {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.grid__link {
		position: relative;
		z-index: 1;
		display: block;
	}

	.grid__img {
		display: block;
		max-width: 100%;
	}

	.grid__title {
		font-size: 0.65em;
		font-weight: 600;
		position: absolute;
		z-index: -1;
		bottom: 0;
		width: 100%;
		text-align: center;
		letter-spacing: 2px;
		text-transform: uppercase;
		opacity: 0;
		color: #fff;
		-webkit-transform: translate3d(0,-20px,0);
		transform: translate3d(0,-20px,0);
		-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
		transition: transform 0.3s, opacity 0.3s;
	}

	.grid__item:hover .grid__title {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}

	.layer {
		position: relative;
		display: block;
	}

	.layer:not(:first-child) {
		position: absolute;
		top: 0;
		left: 0;
	}

	/* Shadow effect */
	.isolayer--shadow .grid__link::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 5px;
		right: 5px;
		bottom: 5px;
		left: 5px;
		opacity: 0.6;
		background: rgba(0,0,0,0.8);
		box-shadow: 0 0 0 0 rgba(0,0,0,0.8);
		-webkit-transform: translateZ(-1px) scale(0.95);
		transform: translateZ(-1px) scale(0.95);
		-webkit-transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
		transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.isolayer--shadow .grid__item:hover .grid__link::before {
		opacity: 0.2;
		box-shadow: 0 0 20px 10px rgba(0,0,0,0.8);
		-webkit-transform: translateZ(-1px) scale(1);
		transform: translateZ(-1px) scale(1);
	}

	/* All individual isometric grid layouts (static and scrollable) */
	.isolayer--scroll1 {
		width: 70vw;
		max-width: 1200px;
		height: calc(100vh - 280px);
	}

	.isolayer--scroll1 .grid__item {
		width: 300px;
		padding: 15px;
	}


	// isolayer Unconventional

	.pseudo-scroller {

	pointer-events: none;

	}

	.isolayer {

	position: absolute;

	top: 0;

	left: 0;

	-webkit-transform-origin: 50% 100%;

	transform-origin: 50% 100%;

	}

	.grid {

	position: relative;

	margin: 0 auto;

	padding: 0;

	list-style: none;

	}

	.grid__item {

	width: 300px;

	}

	.js .grid__item {

	-webkit-backface-visibility: hidden;

	backface-visibility: hidden;

	}

	.grid__link {

	position: relative;

	z-index: 1;

	display: block;

	}

	.grid__img {

	display: block;

	max-width: 100%;

	}

	.grid__title {

	font-size: 0.65em;

	font-weight: 600;

	position: absolute;

	z-index: -1;

	bottom: 0;

	width: 100%;

	text-align: center;

	letter-spacing: 2px;

	text-transform: uppercase;

	opacity: 0;

	color: #fff;

	-webkit-transform: translate3d(0,-20px,0);

	transform: translate3d(0,-20px,0);

	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;

	transition: transform 0.3s, opacity 0.3s;

	}

	.grid__item:hover .grid__title {

	opacity: 1;

	-webkit-transform: translate3d(0,0,0);

	transform: translate3d(0,0,0);

	}

	.layer {

	position: relative;

	display: block;

	}

	.layer:not(:first-child) {

	position: absolute;

	top: 0;

	left: 0;

	}

	/* Shadow effect */

	.isolayer--shadow .grid__link::before {

	content: '';

	position: absolute;

	z-index: -1;

	top: 5px;

	right: 5px;

	bottom: 5px;

	left: 5px;

	opacity: 0.6;

	background: rgba(0,0,0,0.8);

	box-shadow: 0 0 0 0 rgba(0,0,0,0.8);

	-webkit-transform: translateZ(-1px) scale(0.95);

	transform: translateZ(-1px) scale(0.95);

	-webkit-transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;

	transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;

	-webkit-backface-visibility: hidden;

	backface-visibility: hidden;

	}

	.isolayer--shadow .grid__item:hover .grid__link::before {

	opacity: 0.2;

	box-shadow: 0 0 20px 10px rgba(0,0,0,0.8);

	-webkit-transform: translateZ(-1px) scale(1);

	transform: translateZ(-1px) scale(1);

	}

	/* All individual isometric grid layouts (static and scrollable) */

	.isolayer--scroll1 {

	width: 70vw;

	max-width: 1200px;

	height: calc(100vh - 280px);

	}

	.isolayer--scroll1 .grid__item {

	width: 300px;

	padding: 15px;

	}

	.isolayer--deco1 {

	width: 1200px;

	height: 900px;

	}

	.isolayer--deco1 .grid__link .layer:first-child:not(img) {

	background: #EB5E28;

	}

	.isolayer--deco1 .grid__link .layer:nth-child(2):not(img) {

	background: #F3BB45;

	}

	.isolayer--deco1 .grid__link .layer:nth-child(3):not(img) {

	background: #7AC29A;

	}

	.isolayer--deco1 .grid__link div.layer {

	width: 370px;

	height: 270px;

	// opacity: 0.4;

	}

	.isolayer--deco1 .grid__item {

	width: 400px;

	height: 300px;

	padding: 15px;

	}

	.isolayer--deco1 .grid__item:nth-child(2) {
		margin-top: 135px;
	}

	.isolayer--deco1 .grid__item:nth-child(6){
		margin-top: 10px;
	}

	.isolayer--deco2 {

	width: 1200px;

	height: 100vh;

	}

	.isolayer--deco2 .grid__item {

	padding: 20px;

	}

	.isolayer--deco2 .grid__link .layer {

	border: 20px solid #fff;

	border-width: 40px 20px;

	box-shadow: -1px 1px 5px rgba(0,0,0,0.08);

	}

	.first-card{

	top: -20px !important;

	}

	.first-card .layer:not(img) {

	height: 260px !important;

	width: 365px !important;

	margin: 5px 0 0 5px;

	border-radius: 6px;

	}

	.first-card img{

	border-radius: 6px;

	}

	.second-card{

	top: 80px !important;

	}

	.second-card .layer:not(img){

	height: 170px !important;

	border-radius: 6px;

	}

	.second-card img{

	border-radius: 6px;

	}

	.third-card .layer:not(img){

	height: 460px !important;

	width: 275px !important;

	margin: 5px 0 0 5px;

	border-radius: 6px;

	}

	.third-card{

	top: 290px !important;

	}

	.third-card img{

	border-radius: 6px;

	width: 280px !important;

	}

	.third-card .grid__link::before{

	top: 60px;

	height: 410px;

	width: 275px;

	}

	.fourth-card{

	top: 270px !important;

	}

	.fourth-card .layer:not(img){

	height: 450px !important;

	width: 360px !important;

	margin: 5px 0 0 5px;

	border-radius: 6px;

	}

	.fourth-card img{

	border-radius: 6px;

	}

	.fifth-card{

	top: 660px !important;

	left: 400px !important;

	}

	.fifth-card .layer:not(img){

	height: 400px !important;

	width: 364px !important;

	margin: 5px 0 0 5px;

	border-radius: 6px;

	}

	.fifth-card img{

	border-radius: 6px;

	}

	.sixth-card{

	top: 485px !important;

	}

	.sixth-card .layer:not(img){

	height: 145px!important;

	width: 364px !important;

	margin: 5px 0 0 5px;

	border-radius: 6px;

	}

	.sixth-card img{

	border-radius: 6px;

	}

	.seventh-card{

	top: 755px !important;

	}

	.seventh-card .layer:not(img){

	height: 435px !important;

	width: 365px !important;

	margin: 5px 0 0 5px;

	border-radius: 6px;

	}

	.seventh-card img{

	border-radius: 6px;

	}

	.eight-card{

	top: -215px !important;

	left: 400px !important;

	}

	.eight-card img{

	border-radius: 6px;

	}

	.eight-card .layer:not(img){

	height: 480px !important;

	border-radius: 6px;

	}

	.ninth-card{

	top: 790px !important;

	left: 800px !important;

	}

	.ninth-card .grid__link::before{

	width: 280px !important;

	height: 370px !important;

	}

	.ninth-card .layer:not(img){

	width: 280px !important;

	height: 360px !important;

	border-radius: 6px;

	}

	.ninth-card img{

	border-radius: 6px;

	}

	.isolayer{
		transform: translateX(29vw) translateY(-377px) rotateX(50deg) rotateZ(26deg) !important;
		z-index: 1;
	}
}
