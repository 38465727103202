@mixin tag-color ($color){
         background-color: $color;
         color: $white-color;
         border:none;
         .tagsinput-remove-link{
             color: $white-color;
         }
     .tagsinput-add{
         color: $color;
     }
}

@mixin create-colored-tags(){
    &.tag-primary{
        @include tag-color($brand-primary);
    }
    &.tag-info {
        @include tag-color($brand-info);
    }
    &.tag-success{
        @include tag-color($brand-success);
    }
    &.tag-warning{
        @include tag-color($brand-warning);
    }
    &.tag-danger{
        @include tag-color($brand-danger);
    }
}
